import { render, staticRenderFns } from "./PartnerMerchants.vue?vue&type=template&id=62e5be62&scoped=true&lang=pug"
import script from "./PartnerMerchants.vue?vue&type=script&lang=js"
export * from "./PartnerMerchants.vue?vue&type=script&lang=js"
import style0 from "./PartnerMerchants.vue?vue&type=style&index=0&id=62e5be62&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62e5be62",
  null
  
)

export default component.exports