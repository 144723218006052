<template lang="pug">
	.card-basic.partner-merchants(v-if="items.length > 0")
		p.b1.mb-4 {{ $t('partner.merchantTableTitle') }}

		.d-flex.flex-wrap
			.partner-merchants__item(v-for='i in items' :key='i.id')
				.d-flex.align-items-center.mb-4
					.d-flex.justify-content-center.align-items-center.partner-merchants__avatars(:class="{'brand-gradient': !i.avatar}")
						img(v-if="i.avatar" :src="i.avatar.contentUrl")
						p.mb-0(v-if="!i.avatar") {{ `${i.tgUsername}`.slice(0,1) }}
					span.b1 {{ i.tgUsername }}
				dropdown-partner(:list="i.projects.map(x => x)" :id="i.id")
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { USER_ROLES } from '@/models/user';
import DropdownPartner from '@/components/Dropdown/DropdownPartner';

export default {
	name: 'PartnerMerchants',
	components: {
		DropdownPartner
	},
	computed: {
		...mapGetters({
			items: 'users/items'
		})
	},
	mounted() {
		this.get({role: USER_ROLES.MERCHANT})
			.then(v => {})
			.catch(v => {});
	},
	methods: {
		...mapActions({
			get: 'users/items'
		})
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.partner-merchants {
	&__item {
		padding: 20px;
		width: calc(33.30% - 13.3px);
		border-radius: var(--border-radius-rounded);
		border: 1px solid var(--border-line);
		background: var(--foreground-color);
		margin: 0 20px 20px 0;

		@include respond-below(sm) {
			width: 100%;
			margin-right: 0;
		}

		&:nth-child(3n + 3) {
			margin-right: 0;
		}
	}

	&__avatars {
		width: 55px;
		min-width: 55px;
		height: 55px;
		min-height: 55px;
		border-radius: 50%;
		margin-right: 10px;

		p {
			font-size: 14px;
			line-height: 14px;
			text-transform: uppercase;
			color: var(--foreground-color);
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}

		&.brand-gradient {
			background: var(--brand-gradient-primary, linear-gradient(227deg, #0071F4 0%, #66B4FF 100%));
		}
	}
}
</style>
